declare let Office: any;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export function InsertPixel(body: string, chromeId: string) {
  if (isPixelAlreadyInserted(body)) {
    return;
  }
  const parser = new DOMParser();
  const document = parser.parseFromString(body, "text/html");

  const signatureElement = document.getElementById("Signature");
  if (!signatureElement) {
    return body;
  }
  const pixelId =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  const pixelHTML = `<br><img class="lavender-pixel" alt="ltp|${pixelId}" style="width: 1px; height: 1px" src="${backendUrl}/p.png?pixel_id=${pixelId}"/>`;
  signatureElement.insertAdjacentHTML("beforebegin", pixelHTML);

  const updatedBody = new XMLSerializer().serializeToString(document);
  Office.context.mailbox.item.body.setAsync(
    updatedBody,
    { coercionType: Office.CoercionType.Html },
    (result: any) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        // Complete event before slow email analysis call
        // event.completed({ allowEvent: false, errorMessage: "success" });
        //fetch req to create-pixel
        const url = `${backendUrl}/api/create-pixel?chrome_id=${chromeId}&email_id=${pixelId}`;
        fetch(url)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Failed to create pixel");
          })
          .then(() => {});
      }
    }
  );
}

function isPixelAlreadyInserted(body: string): boolean {
  const parser = new DOMParser();
  const document = parser.parseFromString(body, "text/html");

  const signatureElement = document.getElementById("Signature");
  if (!signatureElement) {
    return true;
  }

  const pixelElements = document.getElementsByClassName("lavender-pixel");
  return pixelElements.length > 0;
}
