/** @format */

import axios from "axios";

// INFO: These function are ported from Dashboard logic, src/helpers/Helper.tsx on Dashboard repo
// These function help make the call to get the Chrome Id
export const setAuthCookies = (
  tk: string,
  tks: string,
  rtkn: string,
  csrf: string
) => {
  axios.defaults.headers.common["lav-token"] = tk;
  axios.defaults.headers.common["lav-tokens"] = tks;
  axios.defaults.headers.common["lav-refresh"] = rtkn;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrf;

  const d = new Date();
  const rd = new Date();
  d.setTime(d.getTime() + 15 * 60 * 1000); //15 mins
  rd.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); //7 Days refresh token timer

  const expires = "expires=" + d.toUTCString() + ";";
  const refreshTknExpires = "expires=" + rd.toUTCString() + ";";

  document.cookie =
    "lav-token=" + tk + ";" + expires + secureCookieAttr() + "path=/";
  document.cookie =
    "csrf-token=" + csrf + ";" + expires + secureCookieAttr() + "path=/";

  document.cookie =
    "lav-tokens=" +
    tks +
    ";" +
    refreshTknExpires +
    secureCookieAttr() +
    "path=/";
  document.cookie =
    "lav-refresh=" +
    rtkn +
    ";" +
    refreshTknExpires +
    secureCookieAttr() +
    "path=/";
};

export const secureCookieAttr = () => {
  if (isDev()) {
    return "";
  } else {
    return "Secure;";
  }
};
export const isDev = () => {
  const curEnv = process.env.REACT_APP_ENVIRONMENT || "";
  if (["prod", "test"].includes(curEnv)) {
    return false;
  } else {
    return true;
  }
};
