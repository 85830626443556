/** @format */
import React, { useState, useEffect } from "react";
import { Text, Icon, Button } from "@lavender-ai/lav-components";
import GoogleButton from "./images/google-login-button.png";
import MicrosoftButton from "./images/microsoft-login-button.png";
import axios from "axios";
import { setAuthCookies } from "./utils/login";
import LoginLogo from "./images/login-background-logo.svg";
import LoginFooter from "./images/login-footer-background.svg";
import styled from "styled-components";
import "./css/bundle.css";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
  // INFO: Bug with office.js and react router, so we won't use react router for now
  // https://github.com/OfficeDev/office-js/issues/429#issuecomment-1710733794
  // so we will use window.location.pathname to determine if we are on the login page or not, not ideal but for this small app it will do

  // INFO: if we are on the /handle-sign-in page that was redirect from our backend after successful Google/Microsoft login, then we will call /auth-handshake
  // to get the tokens for get user call to set the chrome id
  const isAuthenticating = window.location.pathname === "/handle-sign-in";
  const token = window.location.search.split("t=")[1];

  let errorMessage = "";
  if (isAuthenticating && token) {
    // INFO: user already login via Google or Microsoft, so we will call /auth-handshake inside of the POPUP window
    return <AuthenticatingPage token={token} />;
  } else if (isAuthenticating && !token) {
    // INFO : show error message and tell user to try again
    console.error("ERROR: No token found in query string");
    errorMessage = "Something went wrong. Please try again.";
  }

  // INFO: report some backend error message to user from our end
  const rawErrorMessage = window.location.search.split("error=")[1];
  if (rawErrorMessage) {
    errorMessage += " " + decodeURIComponent(rawErrorMessage);
  }
  if (errorMessage) {
    return <ErrorPage />;
  }
  // INFO: only the main login page shows the error messages.
  return <LoginPage />;
};

// INFO: this is the default login page
const LoginPage = () => {
  // INFO: avoid double click on the login button to prevent multiple popup windows
  const [hasClicked, setHasClicked] = useState(false);
  return (
    <LoginBackground>
      <div>
        <img src={LoginLogo} alt="Login Logo" />
      </div>
      <LoginInputContainer>
        <section>
          <SignInInfo>
            <Text size="subtext5">Sign in</Text>
            <SignInInfoBody size="body1">
              Once you’ve signed in, Lavender will activate in your Outlook
              account.
            </SignInInfoBody>
          </SignInInfo>
          <SignInButtonContainer>
            <LoginButton
              onClick={callAuthPreflight("/g-sign-in", setHasClicked, false)}
              disabled={hasClicked}
            >
              <img src={GoogleButton} alt="Google Login Button" />
            </LoginButton>
            <LoginButton
              onClick={callAuthPreflight("/m-sign-in", setHasClicked, false)}
              disabled={hasClicked}
            >
              <img src={MicrosoftButton} alt="Microsoft Login Button" />
            </LoginButton>
            <DataEncryptedCopy size="body1">
              <ShieldLockIcon
                variant="IconShieldLock"
                size="mini"
                color="dark"
              />{" "}
              Data Encrypted Safe & Private.
            </DataEncryptedCopy>
          </SignInButtonContainer>
          <Divider />
          <RestrictSignIn>
            <p>Sign in with restricted scopes</p>
            <div>
              <a
                href="/"
                onClick={callAuthPreflight("/g-sign-in", setHasClicked, true)}
              >
                Google
              </a>
              &nbsp;or&nbsp;
              <a
                href="/"
                onClick={callAuthPreflight("/m-sign-in", setHasClicked, true)}
              >
                Microsoft
              </a>
            </div>
          </RestrictSignIn>
        </section>
        <Footer>
          <LoginFooterImageContainer>
            <LoginFooterImage src={LoginFooter} alt="Login Footer" />
          </LoginFooterImageContainer>
          <ContactUs size="body1">
            <a href="https://help.lavender.ai/">Contact us</a>
          </ContactUs>
        </Footer>
      </LoginInputContainer>
    </LoginBackground>
  );
};
const ErrorPage = () => {
  return (
    <LoginBackground>
      <div>
        <img src={LoginLogo} alt="Login Logo" />
      </div>
      <LoginInputContainer>
        <SignInInfo>
          <Text size="subtext5">Sign in</Text>
          <ErrorInfoBody>
            <RowContainer>
              <Icon variant="IconCircleX" size="medium" color="negative" />
              <ErrorCopyContainer>
                <ErrorTitle size="subtext2">Something went wrong</ErrorTitle>
                <ErrorCopy size="body1">
                  We are unable to fulfill your request. Please try again.
                </ErrorCopy>
              </ErrorCopyContainer>
            </RowContainer>
            <RefreshButton
              htmlType="button"
              prefix="IconRefresh"
              onClick={() => {
                window.location.assign("/");
              }}
              radius="xl"
              size="medium"
              state="initial"
              variant="default"
            >
              <Text size="subtext1">Refresh</Text>
            </RefreshButton>
          </ErrorInfoBody>
        </SignInInfo>
        <PrivatizeContainer>
          <DataEncryptedCopy size="body1">
            <ShieldLockIcon variant="IconShieldLock" size="mini" color="dark" />{" "}
            Data Encrypted Safe & Private.
          </DataEncryptedCopy>
        </PrivatizeContainer>
        <LoginFooterImage src={LoginFooter} alt="Login Footer" />
        <ContactUs size="body1">
          <a href="mailto:support@lavender.ai">Contact us</a>
        </ContactUs>
      </LoginInputContainer>
    </LoginBackground>
  );
};

const ErrorInfoBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--size-12);
  align-items: center;
  gap: Size/16;
  align-self: stretch;
  border-radius: var(--radius-md-lrg, 12px);
  background: var(--color-tart-50, #fadad6);
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ErrorTitle = styled(Text)`
  margin-top: 0 !important;
`;
const ErrorCopy = styled(Text)`
  margin-top: var(--size-12) !important;
`;
const ErrorCopyContainer = styled.div`
  margin-left: var(--size-12);
  display: flex;
  flex-direction: column;
`;
const RefreshButton = styled(Button)`
  font-size: 600;
  margin-top: 20px;
  .lav-components-text {
    margin: 0 !important;
  }
`;
const PrivatizeContainer = styled.section`
  margin-bottom: 30vh;
`;
const SignInInfo = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);
`;
const SignInInfoBody = styled(Text)`
  color: var(--color-neutral-purple-600, #858092) !important;
`;
const DataEncryptedCopy = styled(SignInInfoBody)`
  padding: 8px 16px;
`;
const ShieldLockIcon = styled(Icon)`
  vertical-align: sub;
`;
const SignInButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-10);
  align-self: stretch;
  margin: 20px 0;
`;
const LoginButton = styled.button`
  all: unset;
  height: 32px;
  border-radius: var(--radius-xl, 32px);
  background: var(--color-base-White, #fff);

  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    cursor: pointer;
  }
  img {
    height: 34px;
  }
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  align-self: stretch;
  border-radius: var(--radius-none, 0px);
  background: var(--color-neutral-purple-100, #ececee);
  margin: 20px 0;
`;
const ContactUs = styled(Text)`
  font-weight: 600 !important;
  width: 100%;
  text-align: center;
  a {
    all: unset;
    &:hover {
      cursor: pointer;
    }
  }
`;
const LoginBackground = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  background: linear-gradient(180deg, #5318cc 0%, #6a2be9 14%, #cdaff3 56.38%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const LoginFooterImageContainer = styled.div`
  position: relative;
  height: 22dvh;
  width: 100%;
`;
const LoginFooterImage = styled.img`
  position: relative;
  right: 30px;
  width: 114dvw;
`;
const LoginInputContainer = styled.div`
  // layout
  display: flex;
  padding-top: var(--size-32, 32px);
  padding: var(--size-32, 32px) 20px 8px 20px;
  flex-direction: column;
  gap: var(--size-20);
  align-items: center;
  margin: 8px;
  height: 74vh;
  //style
  border-radius: var(--radius-lrg, 16px);
  border: 1px solid var(--color-neutral-purple-100, #ececee);
  background: var(--color-base-White, #fff);
  justify-content: space-between;
`;
const Footer = styled.section`
  width: 100%;
`;
const RestrictSignIn = styled.section`
  text-align: center;
  & p {
    color: black;
    margin-bottom: 8px;
  }
  & a {
    color: var(--color-primary-lavender, #6a2be9);
    font-weight: bold;
    &:visited {
      color: var(--color-primary-lavender, #6a2be9);
    }
  }
`;

// INFO: this is the Authenticating POPUP page that will setup the chrome ID after a successful login
const AuthenticatingPage = ({ token }: { token: string }) => {
  // INFO: this useEffect will call /auth-handshake to finalize the login process with the chrome id after the render
  useEffect(() => {
    if (token) {
      callAuthHandshake(token);
    }
  }, [token]);
  return <div>Authenticating...</div>;
};
// INFO: This is the call to our backend to get the tokens to set the axios header to make the call to get user data
const callAuthHandshake = (token: string) => {
  axios
    .post(`${backendUrl}/auth-handshake`, { t: token })
    .then((resp) => {
      const { tk, tks, rtkn, csrf } = resp.data;
      if (tk && tks) {
        setAuthCookies(tk, tks, rtkn, csrf);
        callGetLoggedInUser();
      }
    })
    .catch(() => {
      // INFO show error message for not getting Chrome id on the Login page
      console.error("ERROR: Unable to fulfill request to get user data");
      window.location.assign("/?error=Unable%20to%20fulfill%20request");
    });
};

// INFO: This is the call to our backend to get the chrome id to set the local storage and page element
const callGetLoggedInUser = () => {
  axios
    .get(`${backendUrl}/users/get-logged-in-users`)
    .then((resp) => {
      if (resp.data.status !== "error") {
        if (!resp.data || resp.data.length === 0) {
          // INFO show error message for not getting Chrome id on the Login page
          console.error("ERROR: No user found");
          window.location.assign("/?error=No%20user%20found");
          return;
        }
        const chromeIdsArray = resp.data.map((user: any) => {
          return `${user.Id}-${user.ApiKey}`;
        });
        const chromeIds = chromeIdsArray.join("|");
        // INFO: set the chrome id to the local storage on the Parent extension page first before you set it on the Popup
        // due to edge case with timer on App.tsx
        // INFO: for Microsoft Edge, this will not run. This edge case is handle on App.tsx
        if (window.opener) {
          window.opener.localStorage.setItem("lv-chrome-id", chromeIds);
          // INFO: close the popup window after setting the chrome id
          window.close();
        }
      } else {
        // INFO show error message for not getting Chrome id on the Login page
        console.error("ERROR: Unable to fulfill request to get user data");
        window.location.assign("/?error=Unable%20to%20fulfill%20request");
      }
    })
    .catch(() => {
      // INFO show error message for not getting Chrome id on the Login page
      console.error("ERROR: Unable to fulfill request to get user data");
      window.location.assign("/?error=Unable%20to%20fulfill%20request");
    });
};

// INFO: This is the preflight call to our backend to get the token to redirect to Google login
const callAuthPreflight =
  (path: string, setHasClicked: Function, isRestricted: boolean = false) =>
  (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | Event
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setHasClicked(true);
    axios
      .post(`${backendUrl}/auth-preflight`)
      .then((resp) => {
        setHasClicked(false);
        const redirect = `${backendUrl}${path}?t=${
          resp?.data?.token
        }&redirect=outlook${isRestricted ? "&restricted=true" : ""}`;
        //INFO: Make popup window to show the Google/Microsoft SSO login page
        window.open(redirect, "_blank", "width=620,height=800");
      })
      .catch(() => {
        setHasClicked(false);
        // INFO show error message for not getting Chrome id on the Login page
        console.error("ERROR: Unable to fulfill request to get user data");
        window.location.assign("/?error=Unable%20to%20fulfill%20request");
      });
  };

export default Login;
