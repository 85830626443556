/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// INFO: strick mode causing component to render twice, so we will not use it for now
// This will cause the component to render twice and make duplicate network requests if you are using useEffect.
//https://stackoverflow.com/questions/72489140/react-18-strict-mode-causing-component-to-render-twice
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
