/** @format */

import React, { useState, useEffect } from "react";
import "./css/App.css";
import Extension from "./Extension";
//import Extension from "./OldExtension";
import Login from "./Login";

// INFO: Bug with office.js and react router, so we won't use react router for now
// https://github.com/OfficeDev/office-js/issues/429#issuecomment-1710733794

const chromeId = localStorage.getItem("lv-chrome-id");

const initialChromeId = (chromeId: string) => {
  // INFO: The extension will scrape this div to get the chrome id, mirror the logic for dashboard
  const divElement = document.createElement("div");
  divElement.id = "lv-api-key";
  divElement.style.display = "none";
  divElement.textContent = chromeId;
  document.body.appendChild(divElement);
};
let initialAuth: "" | "not_logged_in" | "logged_in" = "";

const checkForChromeId = () => {
  if (chromeId) {
    initialChromeId(chromeId);
  } else {
    // INFO: setup timer to check for chrome id every .5 second
    const interval = setInterval(() => {
      const chromeId = localStorage.getItem("lv-chrome-id");
      if (chromeId) {
        initialChromeId(chromeId);
        clearInterval(interval);
        if (!initialAuth) {
          initialAuth = "logged_in";
        }
      } else {
        if (!initialAuth) {
          initialAuth = "not_logged_in";
        }
      }
    }, 500);
  }
};
checkForChromeId();

const App = () => {
  const chromeId = localStorage.getItem("lv-chrome-id");
  const [userChromeId, setChromeId] = useState<string | null>(
    chromeId as string | null
  );
  useEffect(() => {
    if (!userChromeId) {
      const interval = setInterval(() => {
        const chromeId = localStorage.getItem("lv-chrome-id");
        if (chromeId) {
          setChromeId(chromeId);
          clearInterval(interval);
          if (!initialAuth) {
            initialAuth = "logged_in";
          }
          if (initialAuth === "not_logged_in") {
            window.location.reload();
          }
        } else {
          if (!initialAuth) {
            initialAuth = "not_logged_in";
          }
        }
      }, 500);
    }
  }, [userChromeId]);

  // INFO: for Microsoft Edge, this won't save to local storage on Login.tsx. This will handle that edge case.
  const isHandleSignIn = window.location.pathname === "/handle-sign-in";
  if (isHandleSignIn && userChromeId) {
    if (window.opener) {
      window.opener.localStorage.setItem("lv-chrome-id", userChromeId);
    }
    setTimeout(() => {
      window.opener?.reload();
      window.close();
    }, 1000);
  }

  return (
    <div className="lv-outlook">
      {userChromeId && <Extension />}
      {!userChromeId && <Login />}
    </div>
  );
};

export default App;
